import { Divider, Stack } from '@mui/material';
import { ReactElement } from 'react';

import { Product } from '@app/types/catalog';
interface CardPartTitleProps {
  product: Product;
}

export function CardPartTitle({ product }: CardPartTitleProps): ReactElement {
  return (
    <Stack>
      <Divider
        sx={{
          marginLeft: '-16px',
          marginY: '12px',
          width: 'calc(100% + 32px)',
        }}
      />
      <Divider
        sx={{
          marginLeft: '-16px',
          marginY: '12px',
          width: 'calc(100% + 32px)',
        }}
      />
    </Stack>
  );
}
