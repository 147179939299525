import { AxiosPromise } from 'axios';

import { organizationInstance } from '@app/adapter/axios';
import { Pagination } from '@app/types/common';
import {
  Follow,
  FollowCreate,
  FollowerTypeKey,
  FollowTypeKey,
  Organization,
} from '@app/types/organization';
import { getAuthorizationHeader } from '@app/utils/authorization';
import { filterSyntaxGen } from '@app/utils/network';

export function getOrganizations(options: {
  fingerPrint: string;
  ids: string[];
  limit?: number;
  token: string;
}): AxiosPromise<Pagination<Organization[]>> {
  const urlParams = [['$top', String(options?.limit ?? 100)]];
  const filterParams = [];
  if (options.ids?.length) {
    filterParams.push(`id in ${filterSyntaxGen(options?.ids)}`);
  }
  if (filterParams.length > 0) {
    urlParams.push(['$filter', filterParams.join(' and ')]);
  }
  return organizationInstance
    .get(`/organizations?${new URLSearchParams(urlParams).toString()}`, {
      headers: {
        'x-nb-fingerprint': options.fingerPrint,
        ...getAuthorizationHeader(options.token),
      },
    })
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

export function getOrganization(
  organizationId: string
): AxiosPromise<Organization> {
  return organizationInstance
    .get(`/organizations/${organizationId}`)
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

/**
 * follow
 */
export function createFollow(
  organizationId: string,
  token: string,
  fingerPrint: string,
  payload: FollowCreate
): AxiosPromise<Organization> {
  return organizationInstance
    .post(`/organizations/${organizationId}/follow`, payload, {
      headers: {
        'x-nb-fingerprint': fingerPrint,
        ...getAuthorizationHeader(token),
      },
    })
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

export function getFollows(
  token: string,
  fingerPrint: string,
  type: FollowerTypeKey,
  followerId: string,
  options?: {
    followId?: string;
    followType?: FollowTypeKey;
    limit?: number;
  }
): AxiosPromise<Pagination<Follow[]>> {
  const urlParams = [['$top', String(options?.limit ?? 100)]];
  urlParams.push(['type', type]);
  urlParams.push(['followerId', followerId]);

  const filterParams = [];
  if (options?.followType) {
    filterParams.push(`followType eq '${options.followType}'`);
  }
  if (options?.followId) {
    filterParams.push(`followId eq '${options.followId}'`);
  }
  if (filterParams.length > 0) {
    urlParams.push(['$filter', filterParams.join(' and ')]);
  }
  return organizationInstance
    .get(`/follows?${new URLSearchParams(urlParams).toString()}`, {
      headers: {
        'x-nb-fingerprint': fingerPrint,
        ...getAuthorizationHeader(token),
      },
    })
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}
