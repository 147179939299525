import { LoadingButton } from '@mui/lab';
import {
  Box,
  Typography,
  Stack,
  FormControlLabel,
  TextField,
  Checkbox,
  Avatar,
} from '@mui/material';
import React, { ReactElement, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';

import { convertNewLineToBr } from '@app/components/Product/ResultItemCard';
import { HeadBlock } from '@app/components/Shared/HeadBlock';
import { Loading } from '@app/components/Shared/Loading';
import { loggedInUserState } from '@app/domain/app';
import { orderRegistFormState } from '@app/domain/orders';
import { useProduct } from '@app/hooks/useProduct';
import { identificationsLabel } from '@app/types/user';
import { getTargetPersonLabel } from '@app/utils/catalog';
import { formatDateTime } from '@app/utils/date';

export function OfflineOrientationOrderRegister(): ReactElement {
  const navigate = useNavigate();
  const { isLoading, product, productId } = useProduct();
  const loggedInUser = useRecoilValue(loggedInUserState);
  const [orderState, setOrderSharedState] =
    useRecoilState(orderRegistFormState);

  const isDisableButtonNext = useMemo(() => {
    if (!orderState || !orderState.checked) return true;
    return !orderState?.joinDates.length;
  }, [orderState]);

  if (isLoading) {
    return <Loading />;
  }

  return isLoading ? (
    <Loading />
  ) : (
    <Stack>
      <HeadBlock />
      <Typography variant="h3" textAlign="center" sx={{ mb: 4 }}>
        説明会応募
      </Typography>
      <Stack mb={4}>
        希望の条件をご記入のうえ送信してください。
        <Box>
          <Typography component="span" color="error">
            *
          </Typography>
          は必須事項です
        </Box>
      </Stack>
      <Typography>■参加する日付</Typography>
      <Stack
        sx={{
          ' .MuiCheckbox-root': {
            mt: 0.5,
            py: 0,
          },
        }}
      >
        {/* Todo :応募数上限のチェック */}
        {product?.customFields.schedules.map((schedule, i) => (
          <FormControlLabel
            key={i}
            onChange={(
              event: React.SyntheticEvent<Element, Event>,
              checked: boolean
            ) => {
              setOrderSharedState((prev) => {
                const currentDates = prev?.joinDates || [];
                return {
                  ...prev,
                  joinDates: [...currentDates, schedule.date].filter(
                    (date: string) => date !== schedule.date || checked
                  ),
                };
              });
            }}
            checked={(orderState?.joinDates || []).includes(schedule.date)}
            control={<Checkbox />}
            label={
              <Stack>
                <Typography>{formatDateTime(schedule.date)}</Typography>
              </Stack>
            }
          />
        ))}
      </Stack>
      <Stack spacing={3} mt={3}>
        <Stack>
          <Typography variant="body2">■紹介者名</Typography>
          <TextField
            multiline
            rows={1}
            sx={{ mt: 1 }}
            placeholder="紹介者名を入力してください。"
            value={orderState?.referrerName || ''}
            onChange={(e) => {
              setOrderSharedState((prev) => ({
                ...prev,
                referrerName: e.target.value,
              }));
            }}
          />
        </Stack>

        <Stack>
          <Typography variant="body2">■懇親会</Typography>
          <Typography mt={0.5}>
            日時: {formatDateTime(product?.customFields.socialGatheringDate)}
          </Typography>
          <Typography>
            {convertNewLineToBr(product?.customFields.socialGatheringNote)}
          </Typography>
          <FormControlLabel
            onChange={(e) => {
              setOrderSharedState((prev) => ({
                ...prev,
                isJoinEvent: (e.target as HTMLInputElement).checked
                  ? 'checked'
                  : '',
              }));
            }}
            checked={orderState?.isJoinEvent === 'checked'}
            control={<Checkbox />}
            label="懇親会に参加する"
          />
        </Stack>

        <Stack>
          <Typography variant="body2">■本人確認書類</Typography>
          <Typography>
            ※立場によって、アップロードいただく書類が変わります。
          </Typography>
          {/* Userの証明書表示するか・直接アップロードするか仕様確認中 */}
          {loggedInUser?.customFields.identifications?.[0] && (
            <>
              <Typography>
                {
                  identificationsLabel[
                    loggedInUser?.customFields.identifications?.[0].type
                  ]
                }
              </Typography>
              <Avatar
                sx={{
                  height: '257px',
                  mb: 1,
                  width: {
                    md: '343px',
                    xs: '100%',
                  },
                }}
                variant="square"
              >
                <img
                  src="https://fastly.picsum.photos/id/9/5000/3269.jpg?hmac=cZKbaLeduq7rNB8X-bigYO8bvPIWtT-mh8GRXtU3vPc"
                  alt="avatar"
                  style={{
                    height: '257px',
                    objectFit: 'contain',
                    width: '100%',
                  }}
                />
              </Avatar>
            </>
          )}
        </Stack>

        <Stack>
          <Typography variant="body2">
            ■対象者
            <Typography component="span" color="error">
              *
            </Typography>
          </Typography>
          <Typography variant="body3">
            この説明会の対象者は以下の方です。
          </Typography>
          <Typography>
            {getTargetPersonLabel(product?.customFields.targetPersons)}
          </Typography>
          <Typography>
            {convertNewLineToBr(product?.customFields.targetPersonNote)}
          </Typography>
          <FormControlLabel
            onChange={(e) => {
              setOrderSharedState((prev) => ({
                ...prev,
                checked: (e.target as HTMLInputElement).checked
                  ? 'checked'
                  : '',
              }));
            }}
            control={<Checkbox />}
            checked={orderState?.checked === 'checked'}
            label="対象者を確認し、応募する。"
          />
        </Stack>
      </Stack>

      <LoadingButton
        variant="contained"
        disabled={isDisableButtonNext}
        loading={false}
        onClick={() => {
          navigate(`/orientation/${productId}/orders/confirm`);
        }}
        sx={{ borderRadius: 0.5, mt: 2, mx: 'auto', width: 343 }}
      >
        応募
      </LoadingButton>
    </Stack>
  );
}
