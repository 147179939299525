import React, { ReactElement } from 'react';

import { useProduct } from '@app/hooks/useProduct';
import { OrientationValue } from '@app/types/catalog';
import { OfflineOrientationOrderConfirm } from '@app/views/Order/OfflineOrientationOrderConfirm';
import { OnlineOrientationOrderConfirm } from '@app/views/Order/OnlineOrientationOrderConfirm';

export function OrientationOrderConfirm(): ReactElement {
  const { product } = useProduct();
  if (!product) return <></>;

  switch (product.customFields.orientationType) {
    case OrientationValue.ONLINE:
      return <OnlineOrientationOrderConfirm />;
    case OrientationValue.OFFLINE:
      return <OfflineOrientationOrderConfirm />;
    default:
      return <></>;
  }
}
