interface Qualification {
  name: string;
  status: string;
  value: string;
}

export enum OrganizationStatus {
  BEFORE_REVIEW = 'beforeReview',
  CONFIRMED = 'confirmed',
  IN_REVIEW = 'inReview',
  REJECTED = 'rejected',
}

export enum OrganizationType {
  INDIVIDUAL = '個人',
  ORGANIZATION = '法人',
}

export interface Organization {
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  certifiedQualifications?: Qualification[];
  customFields: OrganizationCustomFields;
  description?: string;
  iconImageUri?: string;
  id: string;
  name: string;
  numberOfProjects?: number;
  ownerId?: string;
  phoneNumber: string;
  pictureUrl?: string;
  postalCode: string;
  qualifications?: Qualification[];
  size?: number;
  status?: OrganizationStatus;
  typeId?: OrganizationType;
}

export interface OrganizationCustomFields {
  access: string;
  addressByCity?: string;
  addressLabel: string;
  addressLine4: string;
  branches: OrganizationBranches[];
  catchphrase: string;
  chatContact: string;
  clinicUrl: string;
  department: string;
  electronicChartMaker: string;
  electronicCharts: string[];
  equipmentOther: string;
  equipments: string[];
  facilityTypes: string[];
  mainImages: string;
  ownerName: string;
  ownerUserId: string;
  phoneNumber: string;
  recruitTypeBeginner: string[];
  recruitTypeExperience: string[];
  remark: string;
}
export interface OrganizationBranches {
  addressLine1: string;
  addressLine2: string;
  name: string;
}

/**
 * follow
 */
export const FollowerType = {
  ORGANIZATION: 'organization',
  PRODUCT: 'product',
  USER: 'user',
} as const;
export type FollowerTypeKey = (typeof FollowerType)[keyof typeof FollowerType];

export const FollowType = {
  ORGANIZATION: 'organization',
  PRODUCT: 'product',
  USER: 'user',
} as const;
export type FollowTypeKey = (typeof FollowType)[keyof typeof FollowType];

export interface Follow {
  createdAt: Date;
  delFlg: number;
  followId: string;
  followType: FollowTypeKey;
  followerId: string;
  followerType: FollowerTypeKey;
  id: string;
  updatedAt: Date;
}

export interface FollowCreate {
  followerId: string;
  followerType: FollowerTypeKey;
}

export const FacilityType: {
  [key: string]: string;
} = {
  '01': '1次診療',
  '02': '1.5次診察',
  '03': '2次診察',
  '04': 'エキゾチック診療',
  '05': '専門診療',
  '06': '救急診療',
  '07': '24時間診療',
  '08': '大動物診療',
} as const;
export type FacilityTypeKey = (typeof FacilityType)[keyof typeof FacilityType];

export const RecruitType: {
  [key: string]: string;
} = {
  '01': '獣医師',
  '02': '愛玩動物看護師',
  '03': '動物看護助手',
  '04': '受付',
  '05': 'トリマー',
  '06': 'その他',
} as const;
export type RecruitTypeKey = (typeof RecruitType)[keyof typeof RecruitType];

export const AddressLabelType: {
  [key: string]: string;
} = {
  '01': '本社',
  '02': '本院',
  '03': '人事部',
  '04': 'その他',
} as const;
export type AddressLabelTypeKey =
  (typeof AddressLabelType)[keyof typeof AddressLabelType];
