import { Box, Grid, Paper, Toolbar } from '@mui/material';
import { Outlet } from 'react-router-dom';

interface BorderLayoutProps {
  width?: number | string;
}

export const BorderLayout: React.FC<BorderLayoutProps> = ({ width = 416 }) => {
  return (
    <>
      <Box>
        <Box component="main" sx={{ flexGrow: 1 }}>
          <Toolbar sx={{ height: 72 }} />
          <Grid
            direction="row"
            justifyContent="center"
            container
            sx={{ my: 3 }}
          >
            <Paper variant="outlined" sx={{ px: 3.5, py: 7, width }}>
              <Outlet />
            </Paper>
          </Grid>
        </Box>
      </Box>
    </>
  );
};
