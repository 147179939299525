import { Organization } from '@app/types/organization';

/**
 * Category
 */
export interface Category {
  children: Category[];
  description: string;
  icon?: {
    type: string;
    value: string;
  };
  id: string;
  name: string;
  numberOfProducts: number;
  parent: null | {
    uri: string;
  };
  status: CategoryStatusKey;
}

export interface CategoryWithCount extends Category {
  children: (Category & { count: number })[];
}

export interface LocationWithCount extends ProductLocation {
  count: number;
}

export interface CategoryProductCount {
  count: number;
  id: string;
}

export interface LocationProductCount {
  count: number;
  id: string;
}

export type CategoryState = {
  id: string;
  name: string;
};

export const CategoryName = {
  INTERNSHIP: 'internship',
  ORIENTATION: 'orientation',
  ORIENTATION_SUB: 'orientationSub',
} as const;
export type CategoryNameKey = (typeof CategoryName)[keyof typeof CategoryName];

export const CategoryStatus = {
  ACTIVE: 'ACTIVE',
  ARCHIVED: 'ARCHIVED',
} as const;
export type CategoryStatusKey =
  (typeof CategoryStatus)[keyof typeof CategoryStatus];

export const CategoryIconType = {
  MATERIAL_ICON: 'materialIcon',
  MATERIAL_SYMBOLS: 'materialSymbols',
} as const;

export const OrientationValue = {
  OFFLINE: 'offline',
  ONLINE: 'online',
} as const;
export type OrientationValueKey =
  (typeof OrientationValue)[keyof typeof OrientationValue];
export type CategoryIconTypeKey =
  (typeof CategoryIconType)[keyof typeof CategoryIconType];

/**
 * Product
 */
export interface Product {
  additionalInformation: string;
  addressLine1: string;
  category?: { id: string; name: string };
  categoryId: string;
  customFields: ProductCustomFields;
  description: string;
  dimensions: string;
  id: string;
  images: Attachment[];
  locationIds: string[];
  locations: ProductLocation[];
  name: string;
  organization?: Organization | string;
  organizationId: string;
  publication: ProductPublication;
  variants: ProductVariant[];
}

export interface ProductVariant {
  createdAt: string;
  description: string;
  id: string;
  price: ProductPrice;
  sku: string;
  title: string;
  updatedAt: string;
}

export interface ProductSchedule {
  date: string;
  note: string;
  occupations: string[];
  orgMaxCount: number;
  timeNote: string;
  times: {
    end: string;
    start: string;
  }[];
}

export interface ProductCustomFields {
  access: string;
  category: CategoryNameKey;
  days: string;
  daysNote: string;
  entryFee: string;
  freeContents?: ProductFreeContent[];
  occupation: string[];
  orderCount: number;
  orderMaxCount: number;
  orientationType: OrientationValueKey;
  parentId: string;
  schedules: ProductSchedule[];
  socialGatheringAccess: string;
  socialGatheringDate: string;
  socialGatheringNote: string;
  socialGatheringTimes: string[];
  subStatus: string;
  supplyConfirmClosingDate: string;
  supplyRecruitClosingDate: string;
  survey: string;
  surveyResponseCount: number;
  surveyStatus: string;
  targetPersonNote: string;
  targetPersons: string[];
  transportationFee: string;
  zoom: string;
}

export interface ProductPublication {
  publishedAt?: string;
  publishedBy?: string;
  since?: string;
  status: ProductPublicationStatusKey;
  until?: string;
}

export interface SearchResultProducts {
  data: Product[];
  nextLink: string;
  scrollY?: number;
  total: number;
}

export interface SearchCondition {
  categoryId?: string | null;
  facilityType?: string[];
  keyword?: string;
  locationIds?: string[];
  occupation?: string[];
  orderBy?: string;
  organizationId?: string;
}

export interface ProductPrice {
  amount: number;
  taxable: boolean;
}

export interface ProductImage {
  alt: string;
  createdAt: string;
  id: string;
  productId: string;
  src: string;
  updatedAt: string;
  variantIds: string[];
}
export interface ProductFeature {
  name: string;
  value: string;
}

export interface ProductFreeContent {
  title?: string;
  value?: string;
}

export const ProductType = {
  INTERNSHIP: 'internship',
  ORIENTATION: 'orientation',
} as const;
export type ProductTypeKey = (typeof ProductType)[keyof typeof ProductType];

export const ProductPublicationStatus = {
  ACTIVE: 'ACTIVE',
  ARCHIVED: 'ARCHIVED',
  DRAFT: 'DRAFT',
} as const;
export type ProductPublicationStatusKey =
  (typeof ProductPublicationStatus)[keyof typeof ProductPublicationStatus];

export interface LocationCustomFields {
  keywords?: string;
  searchOrder?: number;
}

export interface AttributeItem {
  groupName: string;
  key: string;
  value: string;
}

export const AttributeGroupName = {
  FACILITY_TYPE: 'facilityType',
  OCCUPATION: 'occupation',
} as const;
export type AttributeGroupNameKey =
  (typeof AttributeGroupName)[keyof typeof AttributeGroupName];

/**
 * Location
 */
export interface City {
  city: string;
  cityCode: string;
}

export const ProductSearchType = {
  SELECT: 'Select',
} as const;

export interface ProductLocation {
  children?: ProductLocation[];
  code: string | null;
  createdAt?: string;
  customFields?: LocationCustomFields;
  id: string;
  name: string;
  numberOfProducts?: number;
  type: ProductLocationTypeKey;
  updatedAt?: string;
}

export interface ProductLocationFormatted extends ProductLocation {
  groupName: string;
  order?: string | undefined;
}

export const ProductLocationType = {
  CITY: 'city',
  PREFECTURE: 'prefecture',
  REGION: 'region',
} as const;
export type ProductLocationTypeKey =
  (typeof ProductLocationType)[keyof typeof ProductLocationType];

export type SortedProduct = ProductLocationFormatted[];

export const ProductTargetPerson: {
  [key: string]: string;
} = {
  '01': '獣医学生1～6年生',
  '02': '愛玩動物看護学生1～4年生',
  '03': '既卒（有資格）',
  '04': '既卒（無資格）',
  '05': '経験者',
} as const;
export type ProductTargetPersonKey =
  (typeof ProductTargetPerson)[keyof typeof ProductTargetPerson];

export const ProductScheduleOccupation: {
  [key: string]: string;
} = {
  '01': '獣医師',
  '02': '愛玩動物看護師',
  '03': '動物看護助手',
  '04': '受付',
  '05': 'トリマー',
  '06': 'その他',
} as const;
export type ProductScheduleOccupationKey =
  (typeof ProductScheduleOccupation)[keyof typeof ProductScheduleOccupation];

/**
 * attachment
 */
export interface Attachment {
  id?: string;
  ownerId?: string;
  ownerType?: string;
  type?: string;
  url?: string;
}
