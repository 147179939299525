import { Box, Grid, Paper, SxProps } from '@mui/material';
import { Outlet } from 'react-router-dom';

interface OrderLayoutProps {
  sx?: SxProps;
}

export const OrderLayout: React.FC<OrderLayoutProps> = ({ sx }) => {
  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: {
          md: 10,
          xs: 0,
        },
        ...sx,
      }}
    >
      <Grid direction="row" justifyContent="center" container>
        <Paper
          variant="outlined"
          sx={{
            px: {
              md: 6,
              xs: 2,
            },
            py: {
              md: 5.5,
              xs: 4,
            },
            width: {
              md: 664,
              xs: '100%',
            },
          }}
        >
          <Outlet />
        </Paper>
      </Grid>
    </Box>
  );
};
