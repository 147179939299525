import { Chip } from '@mui/material';
import React, { ReactElement } from 'react';

interface LabelTagProps {
  backgroundColor?: string;
  label?: string;
}

export function LabelTag({
  backgroundColor,
  label,
}: LabelTagProps): ReactElement {
  return (
    <Chip
      label={label}
      sx={{
        backgroundColor: backgroundColor || 'primary.main',
        borderRadius: 1,
        color: '#fff',
        mr: 1,
        px: 1,
        py: 0.5,
        width: 'max-content',
      }}
    />
  );
}
