import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import { Divider, Grid, Stack, Typography } from '@mui/material';
import { Fragment, ReactElement } from 'react';
import { Link } from 'react-router-dom';

interface BreadcrumbItem {
  label: string;
  link?: string;
}

interface BreadcrumbProps {
  items: BreadcrumbItem[];
}

export function Breadcrumb({ items }: BreadcrumbProps): ReactElement {
  return (
    <Grid item xs={12}>
      <Stack
        alignItems="center"
        direction="row"
        color="gray.700"
        fontSize="0.875rem"
        sx={{ mx: 4, my: 1.5 }}
      >
        {items.map((item, index) => (
          <Fragment key={index}>
            {index > 0 && (
              <ArrowForwardIosOutlinedIcon
                sx={{ color: 'secondary.main', fontSize: 14, mx: 1 }}
              />
            )}
            {item.link ? (
              <Link to={item.link} style={{ textDecoration: 'none' }}>
                <Typography
                  component="span"
                  color="text.link"
                  fontWeight="500"
                  variant="body2"
                >
                  {item.label}
                </Typography>
              </Link>
            ) : (
              <Typography
                component="span"
                color="text.gray"
                fontWeight="500"
                variant="body2"
              >
                {item.label}
              </Typography>
            )}
          </Fragment>
        ))}
      </Stack>
      <Divider />
    </Grid>
  );
}
