import { Divider, Grid, Stack, Typography } from '@mui/material';
import { useMemo, ReactElement, useEffect, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { getOrganizations } from '@app/adapter/organization-service';
import { convertNewLineToBr } from '@app/components/Product/ResultItemCard';
import { Breadcrumb } from '@app/components/Shared/Breadcrumb';
import { HeadBlock } from '@app/components/Shared/HeadBlock';
import { LabelTag } from '@app/components/Shared/LabelTag';
import { Loading } from '@app/components/Shared/Loading';
import { TopNavBackButton } from '@app/components/TopNav/TopNavBackButton';
import { userAuthInfoSelector } from '@app/domain/app';
import { useDeviceType } from '@app/hooks/useBrowserHooks';
import { useProduct } from '@app/hooks/useProduct';
import { useSetSnackbar } from '@app/hooks/useSetSnackbar';
import hospitalDefaultImage from '@app/static/images/hospitalImageDefault.png';
import { OrientationValue, ProductLocationType } from '@app/types/catalog';
import {
  AddressLabelType,
  FacilityType,
  Organization,
  RecruitType,
} from '@app/types/organization';
import { getProductAddressLabel } from '@app/utils/catalog';
import { isError } from '@app/utils/error';

const breadcrumbItems = [{ label: 'トップ', link: `/` }, { label: '検索結果' }];

export function ParticipationOrganizations(): ReactElement {
  const { isDesktop } = useDeviceType();
  const navigate = useNavigate();
  const authInfo = useRecoilValue(userAuthInfoSelector);
  const setSnackbar = useSetSnackbar();
  const { isLoading, isNoIndex, childProducts, product } = useProduct();
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const prefectureNames = useMemo(() => {
    const productPrefectures = product?.locations.filter(
      (location) => location.type === ProductLocationType.PREFECTURE
    );
    return productPrefectures?.map((pre) => pre.name).join('、') || '';
  }, [product?.locations]);
  const cityNames = useMemo(() => {
    const productCities = product?.locations.filter(
      (location) => location.type === ProductLocationType.CITY
    );
    return productCities?.map((city) => city.name).join('、') || '';
  }, [product?.locations]);

  const detailHeadInfo = useMemo(() => {
    //Todo: TDK対応
    const title = `${prefectureNames}${cityNames}`;
    const keywords = `${prefectureNames}`;
    return {
      description: 'Description',
      keywords,
      title,
    };
  }, [cityNames, prefectureNames]);

  const fetchOrganizations = useCallback(
    async (orgIds?: string[]) => {
      if (!authInfo) return;
      try {
        const response = await getOrganizations({
          fingerPrint: authInfo.fingerPrint,
          ids: orgIds || [],
          token: authInfo.accessToken,
        });
        setOrganizations(response.data.value);
      } catch (error) {
        if (isError(error)) {
          setSnackbar(true, error.message);
        }
      }
    },
    [authInfo, setSnackbar]
  );

  useEffect(() => {
    if (childProducts.length) {
      const orgIds = childProducts.map(
        (childProduct) => childProduct.organizationId
      );
      void fetchOrganizations(orgIds);
    }
  }, [childProducts, fetchOrganizations]);

  return (
    <>
      <HeadBlock {...detailHeadInfo} isNoIndex={isNoIndex} />
      {isDesktop && <Breadcrumb items={breadcrumbItems} />}
      {isLoading ? (
        <Loading />
      ) : (
        product && (
          <Grid
            sx={{
              maxWidth: '800px',
              mx: 'auto',
              pb: {
                md: 10,
                xs: 2,
              },
              pt: {
                md: 3,
                xs: 0,
              },
            }}
          >
            <TopNavBackButton onGoBack={() => navigate(-1)} />
            <Stack
              sx={{
                boxShadow: {
                  md: 'none',
                  xs: 'rgba(0, 0, 0, 0.15) 0 2.4px 3.2px',
                },
                flexDirection: {
                  md: 'row',
                  xs: 'column-reverse',
                },
                flexWrap: 'wrap',
                mb: {
                  md: 0,
                  xs: 2,
                },
              }}
            >
              <Stack
                sx={{
                  pr: {
                    md: 2,
                  },
                  px: {
                    md: 0,
                    xs: 2,
                  },
                  py: {
                    md: 0,
                    xs: 2,
                  },
                  width: {
                    md: '50%',
                    xs: '100%',
                  },
                }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    mb: 2,
                  }}
                >
                  {product.name}
                </Typography>
                <LabelTag
                  backgroundColor={
                    product.customFields.orientationType ===
                    OrientationValue.ONLINE
                      ? 'primary.main'
                      : 'neutral.warmOrange'
                  }
                  label={getProductAddressLabel(product)}
                />
              </Stack>
              <Stack
                sx={{
                  borderRadius: {
                    md: 1,
                    xs: 0,
                  },
                  overflow: 'hidden',
                  width: {
                    md: '50%',
                    xs: '100%',
                  },
                }}
              >
                <img
                  src={product.images[0]?.url}
                  alt={product.name}
                  style={{ height: 200, objectFit: 'cover', width: '100%' }}
                />
              </Stack>
            </Stack>
            <Grid
              item
              xs={12}
              sx={{
                mt: {
                  md: 6,
                },
                px: {
                  md: 0,
                  xs: 2,
                },
              }}
            >
              {organizations.map((organization) => (
                <Stack key={organization.id}>
                  <Typography
                    variant="h6"
                    fontWeight="500"
                    sx={{
                      mb: 1.5,
                      mt: {
                        md: 3.5,
                        xs: 0,
                      },
                    }}
                  >
                    {organization.name}
                  </Typography>
                  <Divider sx={{ borderColor: 'black !important' }} />
                  <Stack
                    sx={{
                      borderRadius: {
                        md: 1,
                        xs: 0,
                      },
                      mt: 1,
                      overflow: 'hidden',
                      width: {
                        md: '50%',
                        xs: '100%',
                      },
                    }}
                  >
                    {/* Todo: productDefaultImageが必要かを仕様確認中 */}
                    <img
                      src={organization.iconImageUri || hospitalDefaultImage}
                      alt={organization.name}
                      style={{ height: 200, objectFit: 'cover', width: '100%' }}
                    />
                  </Stack>
                  <Typography
                    variant="body2"
                    fontWeight="bold"
                    sx={{
                      mt: 2,
                    }}
                  >
                    {organization.customFields.catchphrase}
                  </Typography>
                  {/* 1.PR */}
                  <Typography
                    variant="body2"
                    sx={{
                      mt: 2,
                    }}
                  >
                    ■PR
                    <Typography>
                      {convertNewLineToBr(organization.description)}
                    </Typography>
                  </Typography>
                  {/* 2.Organization Info */}
                  <Typography
                    variant="body2"
                    sx={{
                      mt: 2,
                    }}
                  >
                    ■病院情報
                    <Typography>
                      {organization.customFields?.addressLabel
                        ? AddressLabelType[
                            organization.customFields.addressLabel
                          ]
                        : ''}
                      : {organization.addressLine1}
                      {organization.addressLine2}
                      {organization.addressLine3}
                      {organization.customFields.addressLine4}
                    </Typography>
                    <Typography>TEL: {organization.phoneNumber}</Typography>
                    <Typography>
                      動物病院数: {organization.customFields.branches.length}
                    </Typography>
                    {organization.customFields.branches?.length && (
                      <Typography>
                        {organization.customFields.branches
                          ?.map(
                            (branch) => `${branch.name}(${branch.addressLine1})`
                          )
                          .join('、')}
                      </Typography>
                    )}
                    <Typography>{organization.customFields.remark}</Typography>
                  </Typography>
                  {/* 3.facilityTypes */}
                  <Typography
                    variant="body2"
                    sx={{
                      mt: 2,
                    }}
                  >
                    ■診療の種類
                    <Typography>
                      {organization.customFields.facilityTypes
                        ?.map((facilityType) => FacilityType[facilityType])
                        .join('、')}
                    </Typography>
                  </Typography>
                  {/* 4.実習募集の病院 …Todo: Confirm Requirement */}
                  <Typography
                    variant="body2"
                    sx={{
                      mt: 2,
                    }}
                  >
                    ■実習募集中の病院
                    {organization.customFields.branches?.length &&
                      organization.customFields.branches.map((branch) => (
                        <Typography>
                          {branch.name}: {branch.addressLine1}
                          {branch.addressLine2}
                        </Typography>
                      ))}
                  </Typography>
                  {/* 5.recruitType */}
                  <Typography
                    variant="body2"
                    sx={{
                      mt: 2,
                    }}
                  >
                    ■募集中の職種
                    <Typography>
                      ・新卒/未経験者:
                      {organization.customFields.recruitTypeBeginner
                        ?.map((recruitType) => RecruitType[recruitType])
                        .join('、')}
                    </Typography>
                    <Typography>
                      ・経験者:
                      {organization.customFields.recruitTypeExperience
                        ?.map((recruitType) => RecruitType[recruitType])
                        .join('、')}
                    </Typography>
                  </Typography>
                </Stack>
              ))}
            </Grid>
          </Grid>
        )
      )}
    </>
  );
}
