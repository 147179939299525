import { AxiosPromise } from 'axios';
import { isEmpty, get, omitBy } from 'lodash';

import { axiosInstance, catalogInstance } from '@app/adapter/axios';
import { DEFAULT_PRODUCT_LIMIT } from '@app/static/constants';
import {
  Category,
  CategoryProductCount,
  CategoryStatusKey,
  LocationProductCount,
  Product,
  ProductLocation,
  ProductLocationTypeKey,
} from '@app/types/catalog';
import { Pagination } from '@app/types/common';
import { getAuthorizationHeader } from '@app/utils/authorization';
import { filterSyntaxGen } from '@app/utils/network';

/**
 * Get product list use catalog0service
 * @param token
 * @param fingerprint
 * @param options
 * @returns
 */

export async function getProducts(options?: {
  categoryIds?: string[];
  expand?: string;
  limit?: number;
  locationCodes?: string[];
  locationIds?: string[];
  nextLink?: string;
  orderBy?: 'createdAt' | string;
  organizationId?: string;
  page?: number;
  parentId?: string;
  productIds?: string[];
}): Promise<AxiosPromise<Pagination<Product[]>>> {
  if (options?.nextLink) {
    return catalogInstance.get(options?.nextLink);
  }
  const pageSize = options?.limit || DEFAULT_PRODUCT_LIMIT;
  const page = options?.page || 1;
  const filterParams = [];
  const urlParams = [
    ['$top', String(pageSize)],
    ['$skip', String((page - 1) * pageSize)],
  ];

  if (options?.categoryIds?.length) {
    filterParams.push(`categoryId in ${filterSyntaxGen(options?.categoryIds)}`);
  }
  if (options?.locationIds?.length) {
    filterParams.push(
      `locationIds in ${filterSyntaxGen(options?.locationIds)}`
    );
  }
  //Todo : 今回はattribute利用しないため、実習先の検索を対応する際に、施設から探すと職種から検索のフィルターを対応
  if (options?.organizationId) {
    filterParams.push(`organizationId eq '${options?.organizationId}'`);
  }

  if (options?.parentId) {
    filterParams.push(`customFields.parentId eq '${options.parentId}'`);
  }

  const productIds = options?.productIds ?? [];
  if (!isEmpty(productIds)) {
    filterParams.push(`id in ${filterSyntaxGen(productIds)}`);
  }

  filterParams.push(`publication.status in ['ACTIVE']`);

  if (filterParams.length > 0) {
    urlParams.push(['$filter', filterParams.join(' and ')]);
  }
  if (options?.expand) {
    urlParams.push(['$expand', options.expand]);
  }
  if (options?.orderBy) {
    urlParams.push(['$orderBy', options.orderBy]);
  }

  return catalogInstance.get<Pagination<Product[]>>(
    `/products?${new URLSearchParams(urlParams).toString()}`
  );
}

/**
 * Get product promoted by super admin
 * @param token
 * @param fingerprint
 * @param options
 * @returns
 *
 * blocks-50d5
 */
export function getPromotedProducts(options?: {
  limit?: number;
  nextLink?: string;
  order?: 'createdAt' | string;
}): AxiosPromise<Pagination<Product[]>> {
  const filterParams = [];
  const urlParams = [['$top', String(options?.limit ?? 50)]];

  // Filter by both Publication Status and isPromoted=true
  filterParams.push(`publication.status in ['ACTIVE']`, `isPromoted eq true`);

  if (options?.nextLink) {
    return catalogInstance.get(options?.nextLink);
  }

  if (filterParams.length > 0) {
    urlParams.push(['$filter', filterParams.join(' and ')]);
  }

  if (options?.order) {
    urlParams.push(['$orderby', options?.order]);
  }

  // Expanding org because we need the contact details
  urlParams.push(['$expand', 'organization']);

  return catalogInstance.get<Pagination<Product[]>>(
    `/products?${new URLSearchParams(urlParams).toString()}`
  );
}

/**
 * Get one product use catalog-service
 * @param token
 * @param fingerprint
 * @param productId
 * @returns
 */
export function getProduct(productId: string): AxiosPromise<Product> {
  const expandParams = ['organization', 'category, variants'];
  const urlParams = [];
  urlParams.push(['$expand', expandParams.join(',')]);

  return catalogInstance
    .get(`/products/${productId}?${new URLSearchParams(urlParams).toString()}`)
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

/**
 * Get category tree
 * @param option can filter
 * @returns category Tree
 */
export function getCategoryTree(option?: {
  limit?: number;
  name?: string;
  status?: CategoryStatusKey;
}): AxiosPromise<Pagination<Category[]>> {
  const filterParams = [];

  if (option?.name) {
    filterParams.push(`name eq '${option.name}'`);
  }
  if (option?.status) {
    filterParams.push(`status eq '${option.status}'`);
  }

  const urlParams = [['$top', String(option?.limit ?? 100)]];
  urlParams.push(['$filter', filterParams.join(' and ')]);
  return catalogInstance.get(
    `/category-tree?${new URLSearchParams(urlParams).toString()}`
  );
}

/**
 * Get Category list
 * @param option
 * @returns
 */
export function getCategories(options?: {
  limit?: number;
  name?: string;
  parentId?: string[];
}): AxiosPromise<{
  '@nextLink': string;
  count: number;
  total: number;
  value: Category[];
}> {
  const filterParams = [];
  const urlParams = [['$top', String(options?.limit ?? 100)]];

  if (options?.name) {
    filterParams.push(`name co '${options?.name}'`);
  }
  if (options?.parentId) {
    const parentIdString = options?.parentId.map((id) => `'${id}'`).join(',');
    filterParams.push(`parentId in [${parentIdString}]`);
  }
  if (filterParams.length > 0) {
    urlParams.push(['$filter', filterParams.join(' and ')]);
  }
  return catalogInstance.get(
    `/categories?${new URLSearchParams(urlParams).toString()}`
  );
}

/**
 * Get Product Count for Categories
 * @param categoryIds the target Id for getting the number of products
 * @returns
 */
export function getProductCountForCategories(
  categoryIds: string[]
): AxiosPromise<{ value: CategoryProductCount[] }> {
  const categoryIdParam = categoryIds.join(',');
  const URLParam = {
    categoryIds: categoryIdParam,
  };
  return catalogInstance.get(
    `/categories:productCount?${new URLSearchParams(URLParam).toString()}`
  );
}

export function getLocationTree(options: {
  '@nextLink'?: string;
  ids?: string[];
  limit?: number;
  type?: ProductLocationTypeKey;
}): AxiosPromise<{
  '@nextLink': string;
  count: number;
  total: number;
  value: ProductLocation[];
}> {
  const expandParams = [];
  const filterParams = [];

  if (!isEmpty(get(options, 'categoryIds', []))) {
    expandParams.push(
      `category($filter=id in ${filterSyntaxGen(
        get(options, 'categoryIds', [])
      )})`
    );
  }
  if (!isEmpty(options.type)) {
    filterParams.push(
      `type in ${filterSyntaxGen([
        get(options, 'type') as ProductLocationTypeKey,
      ])}`
    );
  }
  if (!isEmpty(options.ids)) {
    filterParams.push(`id in ${filterSyntaxGen(get(options, 'ids', []))}`);
  }

  const nextParams = [['$top', String(options?.limit ?? 50)]];

  if (options?.['@nextLink']) {
    nextParams.push(['$nextToken', options?.['@nextLink']]);
  }

  if (filterParams.length > 0) {
    nextParams.push(['$filter', filterParams.join(',')]);
  }
  if (expandParams.length > 0) {
    expandParams.push(['$expand', expandParams.join(',')]);
  }

  return catalogInstance.get(
    `/location-tree?${new URLSearchParams(nextParams).toString()}`
  );
}

export function getLocations(options?: {
  '@nextLink'?: string;
  ids?: string[];
  limit?: number;
  name?: string;
  parentId?: string;
  type?: ProductLocationTypeKey;
}): AxiosPromise<{
  '@nextLink': string;
  count: number;
  total: number;
  value: ProductLocation[];
}> {
  const nextLink = options?.['@nextLink'];

  if (nextLink) {
    return axiosInstance.get(nextLink);
  }
  const expandParams = [];
  const filterParams = [];

  if (!isEmpty(get(options, 'categoryIds', []))) {
    expandParams.push(
      `category($filter=id in ${filterSyntaxGen(
        get(options, 'categoryIds', [])
      )})`
    );
  }
  if (!isEmpty(options?.type)) {
    filterParams.push(
      `type in ${filterSyntaxGen([
        get(options, 'type') as ProductLocationTypeKey,
      ])}`
    );
  }
  if (!isEmpty(options?.ids)) {
    filterParams.push(`id in ${filterSyntaxGen(get(options, 'ids', []))}`);
  }
  if (options?.name) {
    filterParams.push(`name co '${options?.name}'`);
  }

  if (options?.parentId) {
    filterParams.push(`parentId eq '${options?.parentId}'`);
  }

  const nextParams = [['$top', String(options?.limit ?? 50)]];

  if (filterParams.length > 0) {
    nextParams.push(['$filter', filterParams.join(' and ')]);
  }
  if (expandParams.length > 0) {
    expandParams.push(['$expand', expandParams.join(' and ')]);
  }

  return catalogInstance.get(
    `/locations?${new URLSearchParams(nextParams).toString()}`
  );
}

/**
 * Get Product Count for Locations
 * @param locationIds the target Id for getting the number of products
 * @returns
 */
export function getProductCountForLocations(
  locationIds: string[]
): AxiosPromise<{ value: LocationProductCount[] }> {
  const locationIdParam = locationIds.join(',');
  const URLParam = {
    locationIds: locationIdParam,
  };
  return catalogInstance.get(
    `/locations:productCount?${new URLSearchParams(URLParam).toString()}`
  );
}

export function createProductFavorite(
  userId: string,
  productId: string,
  token: string,
  fingerprint: string
): AxiosPromise<string> {
  const url = `/products/${productId}/follow`;

  return catalogInstance
    .post(
      url,
      {
        followerId: userId,
        followerType: 'user',
      },
      {
        headers: {
          'x-nb-fingerprint': fingerprint,
          ...getAuthorizationHeader(token),
        },
      }
    )
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

export function deleteProductFavorite(
  userId: string,
  productId: string,
  token: string,
  fingerprint: string
): AxiosPromise<boolean> {
  const url = `/products/${productId}/unfollow`;

  return catalogInstance
    .post(
      url,
      {
        followerId: userId,
        followerType: 'user',
      },
      {
        headers: {
          'x-nb-fingerprint': fingerprint,
          ...getAuthorizationHeader(token),
        },
      }
    )
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

export function getFavorite(
  token: string,
  fingerprint: string,
  userId: string,
  productId: string
): AxiosPromise<{
  '@nextLink': string;
  '@previous': string;
  count: number;
  total: number;
  value: Product[];
}> {
  return catalogInstance
    .get(
      `/users/${userId}/products/follows?$filter=productId eq '${productId}'`,
      {
        headers: {
          'x-nb-fingerprint': fingerprint,
          ...getAuthorizationHeader(token),
        },
      }
    )
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

export function getFavorites(
  token: string,
  fingerprint: string,
  userId: string,
  opts?: {
    '@nextLink'?: string;
    categoryIds?: string[];
    keyword?: string;
    limit?: number;
    locationCodes?: string[];
    locationIds?: string[];
    order?: 'createdAt' | string;
    organizationExpand?: boolean;
    productExpand?: boolean;
    productIds?: string[];
  }
): AxiosPromise<{
  '@nextLink': string;
  '@previous': string;
  count: number;
  total: number;
  value: Product[];
}> {
  const filterParams: string[] = [];
  const expandParams: string[] = [];

  if (opts?.productIds?.length) {
    filterParams.push(`productId in ['${opts?.productIds.join("','")}']`);
  }

  if (opts?.productExpand) {
    expandParams.push('product');
    filterParams.push(`publication.status in ['ACTIVE']`);
  }

  if (opts?.organizationExpand) {
    expandParams.push('organization');
    expandParams.push('images');
    expandParams.push('variant');
  }

  if (!isEmpty(opts?.keyword)) {
    filterParams.push(`name co '${opts?.keyword}'`);
  }

  const urlParams: Record<string, string> = omitBy(
    {
      $expand: expandParams.join(','),
      $filter: filterParams.join(' and '),
    },
    isEmpty
  );

  if (opts?.['@nextLink']) {
    const nextToken = new URLSearchParams(
      get(opts?.['@nextLink'].split('?'), '[1]', '')
    ).get('$nextToken');

    if (nextToken) {
      urlParams['$nextToken'] = nextToken;
    }
  }

  return catalogInstance
    .get(
      `/users/${userId}/products/follows?${new URLSearchParams(
        urlParams
      ).toString()}`,
      {
        headers: {
          'x-nb-fingerprint': fingerprint,
          ...getAuthorizationHeader(token),
        },
      }
    )
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}
