import {
  OrientationValue,
  OrientationValueKey,
  Product,
  ProductTargetPerson,
  SearchCondition,
} from '@app/types/catalog';

export const getSearchResultUrl = (condition: SearchCondition | null) => {
  const options = [];
  if (condition?.categoryId) {
    options.push(`category=${condition.categoryId}`);
  }

  if (condition?.locationIds && condition?.locationIds.length > 0) {
    options.push(`locations=${condition.locationIds.join(',')}`);
  }
  if (condition?.keyword) {
    options.push(`keyword=${encodeURIComponent(condition.keyword.trim())}`);
  }
  if (condition?.orderBy) {
    options.push(`orderBy=${condition.orderBy}`);
  }
  return `/search/services?${options.join('&')}`;
};

export const getOrientationLabel = (value: OrientationValueKey) => {
  switch (value) {
    case OrientationValue.ONLINE:
      return 'オンライン';
    case OrientationValue.OFFLINE:
      return '現地';
    default:
      return '';
  }
};

export const getProductAddressLabel = (product: Product) => {
  switch (product.customFields.orientationType) {
    case OrientationValue.ONLINE:
      return 'オンライン';
    case OrientationValue.OFFLINE:
      return product.addressLine1;
    default:
      return '';
  }
};

export const getTargetPersonLabel = (targetPersons?: string[]) => {
  return (
    (targetPersons || [])
      .map((o) => ProductTargetPerson[o])
      .filter((o) => !!o)
      .join('、') || ''
  );
};
