import { Box, Card, Divider, Stack, Typography } from '@mui/material';
import { ReactElement, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import { CardPartOrganization } from '@app/components/Product/CardPartOrganization';
import { CardPartTitle } from '@app/components/Product/CardPartTitle';
import { searchResultProductsState } from '@app/domain/catalog';
import add from '@app/static/icons/productCard/add.svg';
import description from '@app/static/icons/productCard/description.svg';
import injection from '@app/static/icons/productCard/injection.svg';
import { Product } from '@app/types/catalog';
interface ResultItemCardProps {
  favoriteSearch?: boolean;
  product: Product;
}

export const convertNewLineToBr = (
  str: string | undefined,
  separator: string | RegExp = /\\r\\n|\\r|\\n|\r\n|\n|\r/g
) => {
  if (!str) {
    return '';
  }
  return str.split(separator).map((s, i) => [s, <br key={i} />]);
};

export function ResultItemCard({ product }: ResultItemCardProps): ReactElement {
  const [resultProducts, setResultProducts] = useRecoilState(
    searchResultProductsState
  );

  const handleClickProduct = useCallback(() => {
    setResultProducts({ ...resultProducts, scrollY: globalThis.pageYOffset });
  }, [resultProducts, setResultProducts]);

  const organization = useMemo(() => {
    return typeof product.organization === 'object'
      ? { ...product.organization }
      : null;
  }, [product.organization]);

  return (
    <Card
      sx={{
        borderRadius: 0,
        color: 'inherit',
        cursor: 'pointer',
        textDecoration: 'none',
      }}
      data-e2e="searchResult-listItem"
      component={Link}
      to={`/products/${product.id}`}
      onClick={handleClickProduct}
    >
      <Stack sx={{ p: 2 }}>
        <CardPartTitle product={product} />
        <Stack direction="row" m={0}>
          <Box width="50%" m={0} pr={1}>
            <Typography
              variant="body2"
              component="h3"
              whiteSpace={'nowrap'}
              display={'flex'}
              alignItems={'center'}
              color={'#979797'}
              mb={'10px'}
            >
              <img
                src={add}
                alt="add"
                width="22"
                height="22"
                style={{ marginRight: '4px' }}
              />
              勤務内容
            </Typography>
          </Box>
          <Box width="50%" m={0} pl={1} ml={{ md: 'auto' }}>
            <Typography
              variant="body2"
              component="h3"
              whiteSpace={'nowrap'}
              display={'flex'}
              alignItems={'center'}
              color={'#979797'}
              mb={'10px'}
            >
              <img
                src={injection}
                alt="injection"
                style={{ marginRight: '4px' }}
              />
              診療科目
            </Typography>
          </Box>
        </Stack>
        <Divider
          sx={{
            marginLeft: '-16px',
            marginY: '12px',
            width: 'calc(100% + 32px)',
          }}
        />
        {product.description && (
          <>
            <Stack direction="column" spacing={1} m={0}>
              <Typography
                variant="body2"
                component="h3"
                whiteSpace={'nowrap'}
                width="100px"
                marginRight={{ md: 4, xs: 3 }}
                display={'flex'}
                alignItems={'center'}
                color={'#949494'}
              >
                <img
                  src={description}
                  width="20"
                  height="20"
                  alt="description"
                  style={{ marginRight: '4px' }}
                />
                仕事内容
              </Typography>
              <Typography
                variant="body2"
                className="text-ellipsis-limit-4-lines"
              >
                {convertNewLineToBr(product.description)}
              </Typography>
            </Stack>
            <Divider
              sx={{
                marginLeft: '-16px',
                marginY: '12px',
                width: 'calc(100% + 32px)',
              }}
            />
          </>
        )}
        {organization && <CardPartOrganization organization={organization} />}
      </Stack>
    </Card>
  );
}
