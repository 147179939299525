import React, { ReactElement } from 'react';

import { useProduct } from '@app/hooks/useProduct';
import { OrientationValue } from '@app/types/catalog';
import { OfflineOrientationOrderRegister } from '@app/views/Order/OfflineOrientationOrderRegister';
import { OnlineOrientationOrderRegister } from '@app/views/Order/OnlineOrientationOrderRegister';

export function OrientationOrderRegister(): ReactElement {
  const { product } = useProduct();
  if (!product) return <></>;
  switch (product.customFields.orientationType) {
    case OrientationValue.ONLINE:
      return <OnlineOrientationOrderRegister />;
    case OrientationValue.OFFLINE:
      return <OfflineOrientationOrderRegister />;
    default:
      return <></>;
  }
}
