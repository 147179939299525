import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { getProduct, getProducts } from '@app/adapter/catalog-service';
import { useSetSnackbar } from '@app/hooks/useSetSnackbar';
import { Product } from '@app/types/catalog';
import { isError } from '@app/utils/error';

export const useProduct = () => {
  const { productId } = useParams();
  const [product, setProduct] = useState<Product | null>(null);
  const [childProducts, setChildProducts] = useState<Product[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isNoIndex, setIsNoIndex] = useState(false);
  const setSnackbar = useSetSnackbar();
  const fetchProduct = useCallback(
    async (withChild = true) => {
      if (!productId) return;
      setIsLoading(true);
      try {
        const result = await getProduct(productId);

        if (withChild) {
          let allChildProduct: Product[] = [];
          let nextLink = '';
          do {
            const { data } = await getProducts({
              expand: 'organization, variants',
              nextLink,
              parentId: productId,
            });

            allChildProduct = [...allChildProduct, ...data.value];
            nextLink = data['@nextLink'] || '';
          } while (nextLink);
          setChildProducts(allChildProduct);
        }

        setProduct(result.data);
        setIsNoIndex(result.data ? false : true);
      } catch (e) {
        if (isError(e)) {
          console.error('fetchProduct', e.message);
        }
        setIsNoIndex(true);
        setSnackbar(true, '求人が見つかりません');
      } finally {
        setIsLoading(false);
      }
    },
    [productId, setSnackbar]
  );
  useEffect(() => {
    void fetchProduct();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId]);
  return {
    childProducts,
    isLoading,
    isNoIndex,
    product,
    productId,
    setIsLoading,
  };
};
