import { Button, Stack, Typography } from '@mui/material';
import React, { ReactElement, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';

import { Dialog } from '@app/components/Shared/Dialog';
import { HeadBlock } from '@app/components/Shared/HeadBlock';
import { loggedInUserState } from '@app/domain/app';
import { orderRegistFormState } from '@app/domain/orders';

export function OrientationOrderCompleted(): ReactElement {
  const loggedInUser = useRecoilValue(loggedInUserState);
  const [isOpenModal, setIsOpenModal] = React.useState(
    !loggedInUser?.customFields.identifications ||
      loggedInUser.customFields.identifications.length === 0
  );
  const [, setOrderRegistState] = useRecoilState(orderRegistFormState);

  useEffect(() => {
    setOrderRegistState(null);
  }, [setOrderRegistState]);

  const navigate = useNavigate();
  return (
    <>
      <HeadBlock />
      <Typography
        variant="h3"
        color="text.textGray"
        textAlign="center"
        sx={{ mb: 4 }}
      >
        説明会受付完了ご通知
      </Typography>
      <Stack
        sx={{
          mb: 4,
          px: {
            md: 4,
            xs: 0,
          },
        }}
      >
        <Typography>
          Vmeetオンライン合同説明会の参加申込みありがとうございます
          <br />
          <br />
          これより皆様は「お友達紹介」が可能になります。
          参加を希望する「同級生・先輩・後輩」がいましたら是非ご紹介ください。
          <br />
          <br />
          ■紹介賞 A.
          <br />
          初めてVmeet説明会に参加する方の紹介
          <br />
          ・1人 2,000円
          <br />
          <br />
          ※Vmeet主催の現地説明会・オンライン説明会両方に参加したことがない方のみ
          B これまでVmeet説明会に参加経験がある方の紹介：1人 1,000円
          <br />
          ※何名参加いただいても構いません。
          <br />
          <br />
          【注意事項】
          <br />
          ※開催間近になりましたら「出欠確認メール」を送付しますので必ずご返信をお願い致します。返信がない方は「欠席」となります。
          ※メールアドレスは「開催の案内・最終確認・zoom入室URL送付」など必要ですので、間違いがないようご注意ください。
          <br />
          ※事務局からメールが送信できない方は、不参加または参加賞は進呈できませんのでご注意ください。
          <br />
          ※病院説明が始まりましたら「ビデオや音声はON」にして頂きます。
          <br />
          <br />
          ◉開催日までにVmeetホームページの注意事項などは必ずご一読お願いします。それでは、当日のご参加をよろしくお願い致します。
          （社）日本動物病院人事協会 Vmeet事務局
        </Typography>
      </Stack>
      <Stack justifyContent="center" direction="row">
        <Button
          color="inherit"
          variant="outlined"
          fullWidth
          sx={{
            borderColor: 'secondary.main',
            borderRadius: 1,
            height: 52,
            width: 343,
          }}
          onClick={() => navigate('/')}
        >
          トップ画面へ戻る
        </Button>
      </Stack>
      <Dialog
        isMaxWidth={false}
        open={isOpenModal}
        content={
          <Stack
            sx={{
              pb: {
                md: 4,
                xs: 2,
              },
              pt: {
                md: 2,
                xs: 1,
              },
            }}
            alignItems="center"
            justifyContent="center"
          >
            <Typography
              variant="h5"
              sx={{
                fontWeight: '500',
                mb: {
                  md: 4,
                  xs: 2,
                },
                textAlign: 'center',
              }}
            >
              本人確認書類が未登録です。
              <br />
              説明会の特典受取時に
              <br />
              必須になります。
            </Typography>
            <Stack direction="row">
              <Button
                color="inherit"
                variant="outlined"
                sx={{
                  borderColor: 'secondary.main',
                  borderRadius: 0.5,
                  px: {
                    md: 4,
                    xs: 1,
                  },
                }}
                onClick={() => {
                  setIsOpenModal(false);
                }}
              >
                後で登録する
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  setIsOpenModal(false);
                  navigate('/profile/identification');
                }}
                sx={{
                  borderRadius: 0.5,
                  ml: 2,
                  px: {
                    md: 4,
                    xs: 1,
                  },
                }}
              >
                いま登録する
              </Button>
            </Stack>
          </Stack>
        }
      />
    </>
  );
}
