export const TypeId = {
  DEMAND: '000',
} as const;
export type TypeIdKey = (typeof TypeId)[keyof typeof TypeId];

export const GiftValues = {
  AMAZON_GIFT: 'amazonGift',
  CASH: 'cash',
  QUO_CARD: 'quoCard',
  UNNECESSARY: 'unnecessary',
} as const;
export type GiftValueType = (typeof GiftValues)[keyof typeof GiftValues];

export const GiftOptions: { label: string; value: GiftValueType }[] = [
  { label: '現金', value: GiftValues.CASH },
  { label: 'Amazonギフト券', value: GiftValues.AMAZON_GIFT },
  { label: 'QUOカード', value: GiftValues.QUO_CARD },
  { label: '受け取らない', value: GiftValues.UNNECESSARY },
];

export const IdentificationTypes = {
  CERTIFICATE: 'certificate',
  DIPLOMA: 'diploma',
  LICENSE: 'license',
  STUDENT_CARD: 'studentCard',
} as const;
export type IdentificationType =
  (typeof IdentificationTypes)[keyof typeof IdentificationTypes];

export const OccupationValues = {
  EXPERIENCED_QUALIFIED: 'experiencedQualified',
  GRADUATION_QUALIFIED: 'graduationQualified',
  GRADUATION_UNQUALIFIED: 'graduationUnqualified',
  STUDENT: 'student',
};
export type OccupationValueTypes =
  (typeof OccupationValues)[keyof typeof OccupationValues];

export const IdentificationStatus = {
  COMPLETE: 'complete',
  CONFIRM: 'confirm',
  REMAND: 'remand',
};
type IdentificationStatusTypes =
  (typeof IdentificationStatus)[keyof typeof IdentificationStatus];

export interface User {
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  avatar: string;
  customFields: UserCustomFields;
  email: string;
  emailVerified: boolean;
  id: string;
  name: string;
  note?: string;
  phoneNumber: string;
  prefectures?: string;
  reviewStats: {
    averageRate: number;
    averageSubRates: {
      attendance: number;
      communication: number;
      followRule: number;
      quality: number;
      reception: number;
      speed: number;
    };
    totalCount: number;
  };
  typeId: TypeIdKey;
}

export interface UserCustomFields {
  addressLine4?: string;
  benefitReceive?: string;
  birthday?: string;
  birthplace?: string;
  desiredJob?: string;
  familyName?: string;
  familyNameKana?: string;
  firstName?: string;
  firstNameKana?: string;
  gender?: string;
  graduationYear?: number;
  identificationStatus?: IdentificationStatusTypes;
  identifications?: UploadFileInfo[];
  laboratory?: string;
  occupation?: OccupationValueTypes | '';
  postalCode?: string;
  promotion?: string;
  publishAddressFlag?: boolean;
  publishPhoneFlag?: boolean;
  receiveGift?: GiftValueType | '';
  schoolDepartment?: string;
  schoolName?: string;
  schoolYear?: number;
  scoutAllowFlag?: boolean;
}

export interface UserUpdate {
  addressLine1?: string;
  addressLine2?: string;
  addressLine3?: string;
  cityId?: string;
  customFields: UserCustomFields;
  note?: string;
  phoneNumber?: string;
  postalCode?: string;
  prefectureId?: string;
  schoolNameExtra?: string;
}

export interface UploadFileInfo {
  attachmentId: string;
  name: string;
  type: IdentificationType;
}

export interface UserEmailUpdate {
  email: string;
}

export interface UserPasswordUpdate {
  newPasswords?: {
    confirmPassword?: string;
    password?: string;
  };
  password?: string;
}

export interface UserSendResetPassword {
  email: string;
}

export const JobChange = {
  ACTIVE: 'active', // 積極的
  CONDITIONS_MATCH: 'conditionsMatch', // いい条件があれば
  NOT_THINKING: 'notThinking', // 今は考えてない
} as const;
export type JobChangeKey = (typeof JobChange)[keyof typeof JobChange];

/**
 * preference
 */
export const PreferenceKeys = {
  NOTIFICATION: 'notification', // 通知設定
  SEARCH_CONDITION: 'searchCondition', // 検索条件
} as const;
export type PreferenceKey =
  (typeof PreferenceKeys)[keyof typeof PreferenceKeys];

export interface PreferenceCreation {
  key: PreferenceKey;
  value: string;
}

export interface Preference {
  createdAt: Date;
  delFlg: number;
  id: string;
  key: string;
  updatedAt: Date;
  userId: string;
  value: string;
}

export interface PreferenceNotification {
  newMessageByEmail: boolean;
  newProductByFollowedOrg: boolean;
  sendRemindDayBeforeWork: boolean;
}

/**
 * attachment
 */
export interface Attachment {
  id?: string;
  ownerId?: string;
  ownerType?: string;
  type?: string;
  url?: string;
}

export interface AttachmentWithFile extends Attachment {
  blob?: Blob;
  name?: string;
}

export interface AttachmentCreation {
  objectId?: string;
  ownerId?: string;
  type?: string;
}

export interface AttachmentCreateByTypes {
  avatarFiles: AttachmentWithFile[] | [];
  identificationFiles: AttachmentWithFile[] | [];
}

export const schoolNameOptions = [
  '',
  '岡山理科大学',
  '岩手大学',
  '岐阜大学',
  '宮崎大学',
  '鹿児島大学',
  '千葉科学大学',
  '帯広畜産大学',
  '大阪府立大学',
  '東京大学',
  '東京農工大学',
  '日本獣医生命科学大学',
  '日本大学',
  '北海道大学',
  '北里大学',
  '麻布大学',
  '酪農学園大学',
];

export const genderOptions = ['男性', '女性'] as const;

export const yearOptions = () => {
  const currentYear = new Date().getFullYear();
  return Array.from({ length: 101 }, (i, index) => currentYear - index);
};

export const gradeOptions = [1, 2, 3, 4, 5, 6];

export const occupationOptions = [
  {
    title: '学生',
    value: OccupationValues.STUDENT,
  },
  {
    title: '卒業(無資格)',
    value: OccupationValues.GRADUATION_UNQUALIFIED,
  },
  {
    title: '卒業(有資格)',
    value: OccupationValues.GRADUATION_QUALIFIED,
  },
  {
    title: '経験者(有資格)',
    value: OccupationValues.EXPERIENCED_QUALIFIED,
  },
];

export const identificationsLabel = {
  certificate: '資格証明書',
  diploma: '卒業証明書',
  license: '証明書',
  studentCard: '学生証明書',
};
