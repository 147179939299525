import { ReactElement } from 'react';
import { Helmet } from 'react-helmet-async';

const META_DEFAULT = {
  DESCRIPTION:
    '【Pro Doctors（プロドクターズ）】医者、医師スポット求人、バイト、定期非常勤求人情報サイトです。医療法人運営、日給10万円など高時給のバイト情報が多数。',
  KEYWORDS: '医師,スポット求人,バイト,定期非常勤,プロドクターズ,Pro Doctors',
  TITLE: '動物病院と学生をつなぐ｜ベスジョブ',
};

type Props = {
  description?: string;
  isNoIndex?: boolean;
  keywords?: string;
  title?: string;
};

export function HeadBlock(props: Props): ReactElement {
  const { description, keywords, title, isNoIndex } = props;
  return (
    <Helmet>
      <title>{title ?? META_DEFAULT.TITLE}</title>
      <meta
        name="description"
        content={description ?? META_DEFAULT.DESCRIPTION}
      />
      <meta name="keywords" content={keywords ?? META_DEFAULT.KEYWORDS} />
      <link rel="canonical" href={window.location.href} />
      {isNoIndex && <meta name="robots" content="noindex, nofollow" />}
    </Helmet>
  );
}

export function getProductsTitle(category?: string, value?: string): string {
  const prefix = value ? `${value}の` : '';
  return category
    ? `${prefix}医師の${category}求人、バイト情報一覧｜Pro Doctors`
    : META_DEFAULT.TITLE;
}

export function getProductsDescription(
  category?: string,
  value?: string
): string {
  const prefix = value ? `${value}の` : '';
  return category
    ? `【Pro Doctors】${prefix}医者、医師${category}求人、バイト求人情報一覧です。医療法人運営で、日給10万円など高時給のバイト情報が多数。`
    : META_DEFAULT.DESCRIPTION;
}

export function getProductsKeywords(category?: string, value?: string): string {
  const prefix = value ? `,${value}` : '';
  return category
    ? `医師,${category}求人,バイト${prefix}`
    : META_DEFAULT.KEYWORDS;
}
