import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { Stack, Typography } from '@mui/material';
import { ReactElement } from 'react';

import { useDeviceType } from '@app/hooks/useBrowserHooks';

interface TopNavBackButtonProps {
  onGoBack: () => void;
}

export function TopNavBackButton({
  onGoBack,
}: TopNavBackButtonProps): ReactElement {
  const { isDesktop } = useDeviceType();
  return (
    <Stack
      onClick={onGoBack}
      sx={{
        alignItems: 'center',
        backgroundColor: {
          md: 'neutral.white',
          xs: '#F6F1F1',
        },
        flexDirection: 'row',
        mb: {
          md: 6,
        },
        mt: {
          md: 1,
        },
        py: {
          md: 0,
          xs: 1.2,
        },
      }}
    >
      {isDesktop ? (
        <ArrowBackOutlinedIcon
          sx={{
            border: '1px solid #DBDFE5',
            borderRadius: 1,
            color: '#828A9B',
            cursor: 'pointer',
            height: 40,
            mr: 1,
            px: 0.5,
            width: 40,
          }}
        />
      ) : (
        <ArrowBackIosNewOutlinedIcon
          sx={{ color: '#828A9B', height: 20, mb: -0.4, mx: 0.5, width: 20 }}
        />
      )}
      <Typography
        color="inherit"
        variant={isDesktop ? 'h3' : 'body2'}
        fontWeight="normal"
      >
        戻る
      </Typography>
    </Stack>
  );
}
