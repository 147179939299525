import { Image } from '@mui/icons-material';
import { Avatar, Box, Typography, Stack } from '@mui/material';
import { ReactElement } from 'react';
import { useRecoilValue } from 'recoil';

import { userAuthInfoSelector } from '@app/domain/app';
import location from '@app/static/icons/productCard/location.svg';
import { Organization } from '@app/types/organization';

interface CardPartOrganizationProps {
  organization: Organization;
}

export function CardPartOrganization({
  organization,
}: CardPartOrganizationProps): ReactElement {
  const authInfo = useRecoilValue(userAuthInfoSelector);
  const orgInfoArray: string[] = [
    organization.customFields?.addressByCity || '',
    organization.customFields?.access || '',
    authInfo ? organization.name : '',
  ].filter((i) => i);

  return (
    <Stack direction="row" justifyContent={'space-between'}>
      <Box pr={2}>
        <Typography
          variant="body2"
          component="h3"
          whiteSpace={'nowrap'}
          marginRight={{ md: 4, sm: 3, xs: 1 }}
          color={'#949494'}
          display={'flex'}
          alignItems={'centers'}
          marginBottom="12px"
        >
          <img
            src={location}
            width="20"
            height="20"
            alt="description"
            style={{ marginRight: '4px' }}
          />
          勤務地/アクセス
        </Typography>
        <Box display={'flex'} flexDirection={'row'}>
          <Avatar
            variant="square"
            sx={{
              borderRadius: '8px',
              height: 32,
              marginRight: '16px',
              width: 32,
            }}
          >
            {organization.name ? organization.name.substring(0, 1) : ''}
          </Avatar>
          <Box color="#828a9b" fontWeight="normal">
            {orgInfoArray.map((value, index) => (
              <Typography
                fontSize={14}
                key={index}
                className="text-ellipsis-limit-2-lines"
              >
                {value}
              </Typography>
            ))}
          </Box>
        </Box>
      </Box>
      {organization && organization.iconImageUri ? (
        <img
          alt={organization.name}
          src={organization.iconImageUri}
          style={{ height: 106, width: 136 }}
        />
      ) : (
        <Avatar
          color="primary"
          variant="square"
          sx={{
            bgcolor: 'secondary.main',
            height: 136,
            width: 106,
          }}
        >
          <Image sx={{ color: 'primary.main', opacity: 0.5 }} />
        </Avatar>
      )}
    </Stack>
  );
}
