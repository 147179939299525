import { Theme } from '@emotion/react';
import { Close as CloseIcon } from '@mui/icons-material';
import {
  Dialog as BaseDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { Breakpoint, SxProps, useTheme } from '@mui/material/styles';
import { ReactElement, useCallback } from 'react';

import { useDeviceType } from '@app/hooks/useBrowserHooks';

interface DialogProps {
  action?: ReactElement;
  content?: ReactElement;
  isMaxWidth?: boolean;
  maxWidth?: false | Breakpoint | undefined;
  onClose?: () => void;
  open: boolean;
  sx?: SxProps<Theme>;
  title?: string;
}

export function Dialog({
  action,
  content,
  onClose,
  open,
  title,
  maxWidth = 'xs',
  sx,
  isMaxWidth,
}: DialogProps): ReactElement {
  const theme = useTheme();
  const isDefaultMode = useMediaQuery(theme.breakpoints.down('md'));
  const isFullScreen = isMaxWidth !== undefined ? isMaxWidth : isDefaultMode;
  const { isMobile } = useDeviceType();
  const handleClose = useCallback((): void => {
    onClose && onClose();
  }, [onClose]);

  return (
    <BaseDialog
      open={open}
      onClose={handleClose}
      fullScreen={isFullScreen}
      fullWidth
      maxWidth={isFullScreen ? false : maxWidth}
      sx={sx}
    >
      <DialogTitle
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        {title && (
          <Typography fontSize={'1.125rem'} fontWeight={700}>
            {title}
          </Typography>
        )}
        {onClose && isMobile && (
          <IconButton color="inherit" onClick={handleClose} size="small">
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent>{content}</DialogContent>
      {action && <DialogActions>{action}</DialogActions>}
    </BaseDialog>
  );
}
