import {
  Savings as SavingsIcon,
  RecentActors as RecentActorsIcon,
  Person as PersonIcon,
  FormatListBulleted as FormatListBulletedIcon,
  Chat as ChatIcon,
  Article as ArticleIcon,
} from '@mui/icons-material';

export const PREFECTURE_OPTIONS = [
  '北海道',
  '青森県',
  '岩手県',
  '宮城県',
  '秋田県',
  '山形県',
  '福島県',
  '茨城県',
  '栃木県',
  '群馬県',
  '埼玉県',
  '千葉県',
  '東京都',
  '神奈川県',
  '新潟県',
  '富山県',
  '石川県',
  '福井県',
  '山梨県',
  '長野県',
  '岐阜県',
  '静岡県',
  '愛知県',
  '三重県',
  '滋賀県',
  '京都府',
  '大阪府',
  '兵庫県',
  '奈良県',
  '和歌山県',
  '鳥取県',
  '島根県',
  '岡山県',
  '広島県',
  '山口県',
  '徳島県',
  '香川県',
  '愛媛県',
  '高知県',
  '福岡県',
  '佐賀県',
  '長崎県',
  '熊本県',
  '大分県',
  '宮崎県',
  '鹿児島県',
  '沖縄県',
] as const;

export const BOTTOM_MENU_ITEMS = [
  {
    key: 3,
    target: '_blank',
    text: 'VHR実習補助規定',
    to: '',
  },
  {
    key: 4,
    target: '_blank',
    text: '個人情報取扱いについて',
    to: '',
  },
  {
    key: 5,
    target: '_blank',
    text: '一般社団法人 日本動物病院人事協会',
    to: '',
  },
  {
    key: 6,
    target: '_blank',
    text: 'Vmeet事務局',
    to: '',
  },
];

export const SIDE_BAR_MENU_ITEMS = [
  {
    icon: PersonIcon,
    isDisabled: false,
    key: 1,
    path: '/setting',
    text: 'アカウント設定',
  },
  {
    icon: ArticleIcon,
    isDisabled: false,
    key: 2,
    path: '/profile/detail',
    text: '会員登録情報設定',
  },
  {
    icon: SavingsIcon,
    isDisabled: false,
    key: 3,
    path: '/',
    text: '口座情報設定(近日追加)',
  },
  {
    icon: RecentActorsIcon,
    isDisabled: false,
    key: 4,
    path: '/profile/identification',
    text: '本人確認書類提出',
  },
  {
    icon: ChatIcon,
    isDisabled: false,
    key: 5,
    path: '/',
    text: 'チャット(近日追加)',
  },
  {
    icon: FormatListBulletedIcon,
    isDisabled: false,
    key: 6,
    path: '/',
    text: '応募実習一覧(近日追加)',
  },
  {
    icon: FormatListBulletedIcon,
    isDisabled: false,
    key: 7,
    path: '/',
    text: '応募説明会一覧(近日追加)',
  },
  {
    icon: FormatListBulletedIcon,
    isDisabled: false,
    key: 8,
    path: '/',
    text: '通知設定(近日追加)',
  },
];

export const SIDE_BAR_SYSTEM_MENU_ITEMS_NO_LOGIN = [
  {
    isDisabled: false,
    key: 1,
    path: '',
    target: '_blank',
    text: '掲載希望の方へ',
  },
];

export const SIDE_BAR_SYSTEM_MENU_ITEMS = [
  {
    isDisabled: false,
    key: 1,
    path: '',
    target: '_blank',
    text: 'Vmeetについて',
  },
  {
    isDisabled: false,
    key: 2,
    path: '',
    target: '_blank',
    text: 'お問い合わせ',
  },
];

/*
  localStorage, sessionStorage を使う場合はここに key を追加
  重複しないように一元管理
*/
export const STORAGE_KEYS = {
  ACCESS_TOKEN: 'accessToken',
  AFTER_LOGIN_ROUTE: 'afterLoginRoute',
  FINGERPRINT: 'fingerprint', // 予約
  REFRESH_TOKEN: 'refreshToken', // 予約
  USER_ID: 'userId',
};

export const ERROR_MESSAGE = {
  EMAIL_NOT_FOUND:
    '入力されたメールアドレスの会員情報が見つかりません。間違ったメールアドレスを入力されていないかご確認ください。',
  INVALID_USER_ID: 'ログアウトします',
  INVALID_USER_TYPE: 'Vmeet側のURLで再度お試しください。',
  TOKEN_EXPIRED: '認証の有効期限が切れました。再ログインしてください。',
};
// Pagination: Designには一行に4Productを表示するため、10 => 12に変更
export const DEFAULT_PRODUCT_LIMIT = 12;
export const DEFAULT_CURRENT_PAGE = 1;
export const DEFAULT_TOTAL_PAGE = 0;

export const OCCUPATION_OPTIONS = [
  '獣医師',
  '愛玩動物看護師',
  '動物看護助手',
  '受付',
  'トリマー',
  'その他',
];

export const ORDER_MAX_COUNT = 10;
